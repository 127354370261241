<template>
  <InfoWraper>
    <div class="nav-author">
      <div class="page-header-actions">
      <sdFeatherIcons type="users" size="14"  style="color: #fff;"/>
        <a href="/Analytics User Guide.pdf" style="padding-right: 15px; color: #fff;" download> User Guide
        </a>
      </div>

      <div class="page-header-actions">
        <a-button size="small" type="primary" v-on:click="xeroconnect()">
          <!-- <sdFeatherIcons type="plus" size="14" /> -->
          Connect 
        </a-button> 
      </div>
    </div>
    <div class="nav-author">
      <sdPopover placement="bottomRight" action="click" >
        <template v-slot:content>
          <UserDropDwon>
            <div class="user-dropdwon">
              <figure class="user-dropdwon__info"> 
                <img
                  v-bind:src="currentuser.profile_pic"
                  alt=""
                  style="height: 50px; width: 50px"
                  v-if="currentuser != null"
                />
                <figcaption>
                  <sdHeading as="h5">{{
                    currentuser.f_name + " " + currentuser.l_name
                  }}</sdHeading>
                  <p>{{ currentuser.email }}</p>
                </figcaption>
              </figure>
              <ul class="user-dropdwon__links">
                <li>
                  <a to="profile" href="profile"> <sdFeatherIcons type="user" /> Profile </a>
                </li>
                <!-- <li>
                  <a to="#"> <sdFeatherIcons type="settings" /> Settings </a>
                </li>
                <li>
                  <a to="#"> <sdFeatherIcons type="dollar-sign" /> Billing </a>
                </li>
                <li>
                  <a to="#"> <sdFeatherIcons type="users" /> Activity </a>
                </li>
                <li>
                  <a to="#"> <sdFeatherIcons type="bell" /> Help </a>
                </li> -->
              </ul>
              <a @click="SignOut" class="user-dropdwon__bottomAction" href="#">
                <LogoutOutlined /> Sign Out
              </a>
            </div>
          </UserDropDwon>
        </template>
        <a to="#" class="head-example">
          <a-avatar
            v-if="currentuser != null"
            v-bind:src="API_ENDPOINT + currentuser.profile_pic"
            style="height: 40px; width: 40px"
          />
        </a>
      </sdPopover>
    </div>
  </InfoWraper>
</template>

<script>
import { InfoWraper, UserDropDwon } from "./auth-info-style";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { LogoutOutlined } from "@ant-design/icons-vue";
import { defineComponent } from "vue";

export default defineComponent({
  name: "AuthInfo",
  props: ["currentuser"],
  watch: {
    currentuser: function (newVal, oldVal) {
      // watch it
     
    },
  },
  components: {
    InfoWraper,
    UserDropDwon,
    LogoutOutlined,
  },
  data() {
    return {
      flag: "english",
    };
  },
  setup() {
    const { dispatch } = useStore();
    const { push } = useRouter();
    const API_ENDPOINT = process.env.VUE_APP_API_ENDPOINT; 
    const SignOut = (e) => {
      e.preventDefault();
      dispatch("logOut").then(() => {
        push("/auth/login");
      });
    };

    const xeroconnect = () => {
      window.location.href = API_ENDPOINT + 'xero/authorization.php?app_id=1';
    };

    return {
      SignOut,
      API_ENDPOINT,
      xeroconnect
    };
  },
  methods: {
    onFlagChangeHandle: function (value) {
      this.flag = value;
    },
  },
});
</script>
