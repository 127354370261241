<template>
  <!-- <a-menu
    v-model:openKeys="openKeys"
    v-model:selectedKeys="selectedKeys"
    :mode="mode"
    :theme="darkMode ? 'dark' : 'light'"
  >
    <a-menu-item @click="toggleCollapsed" key="dashboard">
      <router-link to="/">
        <sdFeatherIcons type="circle" />
        <span>
          Blank Page
        </span>
      </router-link>
    </a-menu-item>
  </a-menu> -->
  <a-menu
    v-model:openKeys="openKeys"
    v-model:selectedKeys="selectedKeys"
    :mode="mode"
    :theme="darkMode ? 'dark' : 'light'"
  >
    <a-menu-item key="Dashboard">
      <router-link to="/">
        <sdFeatherIcons type="list" />
        <span> Dashboard </span>
      </router-link>
    </a-menu-item>
    <a-menu-item key="overview">
      <router-link to="/overview">
        <sdFeatherIcons type="activity" />
        <span> Overview </span>
      </router-link>
    </a-menu-item>
    <a-sub-menu key="balance">
      <template v-slot:title
        ><sdFeatherIcons type="file-text" /><span>Balance Sheet</span></template
      >
      <a-menu-item key="xero_unreconciled">
        <router-link to="xero_unreconciled">
          Unreconciled Bank Transactions
        </router-link>
      </a-menu-item>
      <a-menu-item key="xero_capitalizeinvoice">
        <router-link to="xero_capitalizeinvoice">
          Capitalisation without Invoice
        </router-link>
      </a-menu-item>
      <a-menu-item key="xero_balancesheet">
        <router-link to="xero_balancesheet"> Wrong Values in BS </router-link>
      </a-menu-item>
      <!--<a-menu-item key="xero_interestbooked">
        <router-link to="xero_interestbooked"> Interest - HP </router-link>
      </a-menu-item>-->
      <!--<a-menu-item key="xero_manualbank">
        <router-link to="xero_manualbank">
          Manual Bank Transactions
        </router-link>
      </a-menu-item>-->
    </a-sub-menu>

    <a-sub-menu key="masters">
      <template v-slot:title
        ><sdFeatherIcons type="inbox" /><span>Masters</span></template
      >
      <!--<a-menu-item key="dup_contact">
        <router-link to="dup_contact"> Duplicate Contacts </router-link>
      </a-menu-item>-->
      <a-menu-item key="unuse_chart">
        <router-link to="unuse_chart"> Unused Chart of Accounts </router-link>
      </a-menu-item>
      <a-menu-item key="repeat_sinvoice">
        <router-link to="repeat_sinvoice">
          Repeating Sales Invoice
        </router-link>
      </a-menu-item>

      <a-menu-item key="repeat_pinvoice">
        <router-link to="repeat_pinvoice">
          Repeating Purchase Invoice
        </router-link>
      </a-menu-item>

      <!--<a-menu-item key="xero_journal">
        <router-link to="journal">
         Repeating Journal Schedule
        </router-link>
      </a-menu-item>-->
    </a-sub-menu>

    <a-sub-menu key="nominal">
      <template v-slot:title
        ><sdFeatherIcons type="inbox" /><span>Nominal</span></template
      >
      <!--<a-menu-item key="xero_dupinvoices">
        <router-link to="xero_dupinvoices">Duplicate Invoices</router-link>
      </a-menu-item>-->
      <a-menu-item key="xero_vat">
        <router-link to="xero_vat">Inconsistent VAT Codes</router-link>
      </a-menu-item>
      <a-menu-item key="xero_nominal">
        <router-link to="xero_nominal">Inconsistent Nominal Codes</router-link>
      </a-menu-item>
      <a-menu-item key="xero_nominalvat">
        <router-link to="xero_nominalvat"
          >Inconsistent Nominal Codes with VAT Codes</router-link
        >
      </a-menu-item>
      <a-menu-item key="xero_transnominal">
        <router-link to="xero_transnominal">NO VAT/ZERO RATED /EXEMPT VAT entries for review</router-link>
      </a-menu-item>
      <a-menu-item key="xero_supplier">
        <router-link to="xero_supplier">New Contact Name</router-link>
      </a-menu-item>
      <!--<a-menu-item key="xero_pivotanalysis">
        <router-link to="xero_pivotanalysis"
          >Variance Analysis Pivot Table</router-link
        >
      </a-menu-item>-->

      <!--<a-menu-item key="xero_vatasset">
        <router-link to="xero_vatasset">VAT Mismatch</router-link>
      </a-menu-item>-->
      <a-menu-item key="xero_vatjournal">
        <router-link to="xero_vatjournal">Manual Journals with VAT</router-link>
      </a-menu-item>
      <!--<a-menu-item key="xero_lateentry">
        <router-link to="xero_lateentry">Late Entries</router-link>
      </a-menu-item>
      <a-menu-item key="xero_trackingcat">
        <router-link to="xero_trackingcat">Tracking Categories</router-link>
      </a-menu-item>-->
    </a-sub-menu>

    <a-sub-menu key="ap_ar">
      <template v-slot:title
        ><sdFeatherIcons type="inbox" /><span>AP / AR</span></template
      >
      <a-menu-item key="xero_pay">
        <router-link to="xero_pay">Unallocated Payments</router-link>
      </a-menu-item>
      <a-menu-item key="partial_pay">
        <router-link to="partial_pay">
          Partial Paid Bills </router-link
        >
      </a-menu-item> 
       <a-menu-item key="receipts_allocation">
        <router-link to="receipts_allocation">
          Partial Paid Invoice </router-link>
      </a-menu-item>

      <a-menu-item key="xero_PIdraft">
        <router-link to="xero_PIdraft"> PI Invoices in Drafts</router-link>
      </a-menu-item>
      <a-menu-item key="xero_debit">
        <router-link to="xero_debit">Suppliers with Debit Balances</router-link>
      </a-menu-item>
      <a-menu-item key="xero_supplierinvoices">
        <router-link to="xero_supplierinvoices">SI > 90 Days</router-link>
      </a-menu-item>
      <a-menu-item key="xero_invoicematch">
        <router-link to="xero_invoicematch">Invoices Matched for Later Dates</router-link>
      </a-menu-item>
      <a-menu-item key="xero_receipt">
        <router-link to="xero_receipt">Unallocated Receipts</router-link>
      </a-menu-item>
      <a-menu-item key="xero_SIdraft">
        <router-link to="xero_SIdraft">SI in Drafts</router-link>
      </a-menu-item>
      <a-menu-item key="xero_dabtorinvoices">
        <router-link to="xero_dabtorinvoices">PI > 90 days</router-link>
      </a-menu-item>
      <a-menu-item key="xero_payment_expensed">
        <router-link to="xero_payment_expensed">Payments expensed out but invoices are outstanding</router-link>
      </a-menu-item>
    </a-sub-menu>
    
    <a-sub-menu key="dynamic">
      <template v-slot:title
        ><sdFeatherIcons type="inbox" /><span>Dynamic</span></template
      >
      <a-menu-item key="xero_Fainvoices">
        <router-link to="xero_Fainvoices"> FA Invoices below XX Value</router-link>
      </a-menu-item>
      <a-menu-item key="supplier-invoice">
        <router-link to="supplier-invoice"> O/S Invoices with XX Suppliers</router-link>
      </a-menu-item>
      <!--<a-menu-item key="xero_prepayments">
        <router-link to="xero_prepayments"> Potential Prepayment Report</router-link>
      </a-menu-item>-->
      <!--<a-menu-item key="xero_accrual">
        <router-link to="xero_accrual"> Potential Accrual Report</router-link>
      </a-menu-item>-->
      <a-menu-item key="xero_unreconciled">
        <router-link to="value-invoice">PI/SI Invoices with XX Value</router-link>
      </a-menu-item>
      <a-menu-item key="da_invoices">
        <router-link to="da_invoices">Transaction with Director Name</router-link>
      </a-menu-item>
      <a-menu-item key="xero_Entertainment">
        <router-link to="xero_Entertainment"> Entertainment Expenses</router-link>
      </a-menu-item>
      <a-menu-item key="vat_late">
        <router-link to="vat_late"> Vat Late Claims</router-link>
      </a-menu-item>
    </a-sub-menu>
  </a-menu>
  
</template>
<script>

import {
  computed,
  reactive,
  ref,
  toRefs,
  watch,
  watchEffect,
  defineComponent,
} from "vue";
import VueTypes from "vue-types";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import versions from "../demoData/changelog.json";

export default defineComponent({
  name: "OverviewSide",
  
  props: {
    toggleCollapsed: VueTypes.func,
    events: VueTypes.object,
  },
  setup(props) {
    const store = useStore();
    const darkMode = computed(() => store.state.themeLayout.data);
    const mode = ref("inline");
    const { events } = toRefs(props);
    let showModal= ref(false);
    const {
      onRtlChange,
      onLtrChange,
      modeChangeDark,
      modeChangeLight,
      modeChangeTopNav,
      modeChangeSideNav,
    } = events.value;

    const router = computed(() => useRoute());
    const state = reactive({
      selectedKeys: ["home"],
      openKeys: ["dashboard"],
      preOpenKeys: ["dashboard"],
    });

    watchEffect(() => {
      if (router.value.matched.length) {
        if (router.value.matched.length > 2) {
          state.selectedKeys = [router.value.matched[2].name];
          state.openKeys = [router.value.matched[1].name];
          state.preOpenKeys = [router.value.matched[1].name];
        } else if (router.value.matched.length > 3) {
          state.selectedKeys = [router.value.matched[3].name];
          state.openKeys = [router.value.matched[1].name];
          state.preOpenKeys = [router.value.matched[1].name];
        } else {
          state.selectedKeys = [router.value.matched[1].name];
          state.openKeys = [router.value.matched[1].name];
          state.preOpenKeys = [router.value.matched[1].name];
        }
      }
    });

    watch(
      () => state.openKeys,
      (val, oldVal) => {
        state.preOpenKeys = oldVal;
      }
    );

    return {
      mode,
      ...toRefs(state),
      darkMode,
      onRtlChange,
      onLtrChange,
      modeChangeDark,
      modeChangeLight,
      modeChangeTopNav,
      modeChangeSideNav,
      versions,
    };
  },
});
</script>
